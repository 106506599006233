const awardsData = [
    {
      year: 2023,
      title: "3rd Place",
      award: "Control Award",
      image: require("./images/premii/2023-1.jpg"),
      description: "Premiu pentru cele mai bune programe și strategii aplicate pe robot",
      alt: "Award logos",
    },
    {
      year: 2022,
      title: "1st Place",
      award: "Motivate Award",
      image: require("./images/premii/2022-2.jpg"),
      description: "Premiu pentru echipa care depune un efort colectiv pentru a face cunoscut FIRST și își îndrumă comunitatea să aprecieze această cultură",
      alt: "Award logos",
    },
    {
      year: 2022,
      title: "3rd Place",
      award: "Nație Prin Educație Award",
      image: require("./images/premii/2022-1.jpg"),
      description: "Premiu pentru echipa remarcată cu eforturi unice, dar care nu se încadrează în niciuna dintre categoriile de premii",
      alt: "Award logos",
    },
    {
      year: 2021,
      title: "2nd Place",
      award: "Inspire Award",
      image: require("./images/premii/2021-2.jpg"),
      description: "Premiu pentru echipa care împărtășește experiențele, entuziasmul și cunoștințele lor cu alte echipe, sponsorii, comunitatea lor și judecătorii.",
      alt: "Award logos",
    },
    {
      year: 2021,
      title: "1st Place",
      award: "Connect Award",
      image: require("./images/premii/2021-1.jpg"),
      description: "Pentru echipa care se conectează cel mai mult cu știința locală, tehnologia, ingineria, și comunitatea STEM.",
      alt: "Award logos",
    },
    {
      year: 2020,
      title: "1st Place",
      award: "",
      image: require("./images/premii/2020-2.jpg"),
      description: "Realizarea acestei etape a fost împiedicată din cauza restricțiilor.",
      alt: "Award logos",
    },
    {
      year: 2020,
      title: "1st Place",
      award: "Inspire Award",
      image: require("./images/premii/2020-1.jpg"),
      description: "Pentru echipa care împărtășește experiențele, entuziasmul și cunoștințele lor cu alte echipe, sponsorii, comunitatea lor și judecătorii.",
      alt: "Award logos",
    },
    {
      year: 2019,
      title: "2nd Place",
      award: "Inspire Award",
      image: require("./images/premii/2019-2.jpg"),
      description: "Premiu pentru respectarea Gracious Professionalismului atât în echipă, cât și în afara acesteia. Pentru echipa care împărtășește experiențele, entuziasmul și cunoștințele lor cu alte echipe,  sponsorii, comunitatea lor și judecătorii.",
      alt: "Award logos",
    },
    {
      year: 2019,
      title: "1st Place",
      award: "Finalist Award",
      image: require("./images/premii/2019-1.jpg"),
      description: "Pentru un antrenor sau mentor adult care a oferit îndrumări și sprijin deosebite unei echipe pe tot parcursul anului și le-a demonstrat Gracious Professionalismul.",
      alt: "Award logos",
    },
    {
      year: 2018,
      title: "1st Place",
      award: "Think Award | Control Award",
      image: require("./images/premii/2018.jpg"),
      description: "Premiu pentru cea mai bună proiectare și construire a robotului . Pentru cele mai bune programe și strategii aplicate pe robot.",
      alt: "Award logos",
    },
  ];
  
  export default awardsData;
  